import React from 'react';
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser';
import PageHeader from '../PageComponents/PageHeader';
let gravURL = process.env.REACT_APP_GRAV_URL;
let siteURL = process.env.REACT_APP_APP_URL;
export default class TheScience extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gravContent: this.props.pageContext.frontmatter,
			loading: true,
			pageMedia: gravURL + '/uploads/images/',
			headContent: this.props.pageContext.head,
		}
	}
	componentDidMount(){
		this.setState({loading: false})
		window.scrollTo(0,0)

	}
	render() {
		let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc,} = this.props.pageContext.head;
		return (
			<main>
				<Helmet>
					<title>The Science | New Image Keto | Alpha Lipid™ SDII Programme</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={gravURL + shareImg} />
					<meta property="og:image:url" content={gravURL + shareImg}/>
					<meta name="twitter:image" content={gravURL + shareImg}/>
					<meta name="twitter:card" content={gravURL + shareImg}/>
				</Helmet>	
				
					<div className="sciencePageWrapper clearfix">
						<div className="pageGrid">
							<section className="pt-8">
							<PageHeader mainHeading={this.state.gravContent.title} subHeading="behind the ketogenic diet"/>
							</section>
							<div className="theScienceCopyWrapper">
								{ReactHtmlParser(this.state.gravContent.pageContent)}
							</div>
						</div>
						<div className="mt-5">
							<img src={this.state.pageMedia + this.state.gravContent.scienceBanner} 
							alt="All about the science of the ketogenic diet  NewImage Keto" />
						</div>
					</div>
			</main>
		);
	}
}
